<template>
  <div>
    <div style="width: auto; border: 1px solid gray; display: inline-block">
      <vue-cropper
        :alt="alt"
        :auto-crop-area="0.8"
        :background="true"
        :cropBoxResizable="resizeable"
        :guides="false"
        :imgStyle="imgStyle"
        :maxHeight="cropHeight"
        :maxWidth="cropWidth"
        :min-container-height="cropHeight"
        :min-container-width="cropWidth"
        :minCropBoxHeight="cropHeight"
        :minCropBoxWidth="cropWidth"
        :scalable="false"
        :src="imgSrc"
        :view-mode="3"
        dragMode="none"
        ref="cropper"
        style="
           {
            'width':'auto','height': 'auto';
          }
        "
      >
      </vue-cropper>
    </div>
    <br />
    <br />
    <v-btn @click="cropImage" class="my-btn-crop" small type="button"
      >Crop</v-btn
    >
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
export default {
  name: "ImageCropper",
  components: {
    VueCropper,
  },
  props: ["imgSrc", "cropWidth", "cropHeight", "alt", "imgStyle", "resizeable"],
  data() {
    return {
      cropImg: "",
    };
  },
  methods: {
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.$emit("croped_file", this.cropImg);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn-crop {
  padding: 8px 60px;
}

.my-btn-crop {
  height: 38px;
  border: 0.75px solid #2758f6;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.23);
  color: #2758f6;
  margin-bottom: 0rem !important;
  font-size: 14px;
  padding: 8px 44px;
}

.my-btn-crop:hover {
  color: #2758f6;
}
</style>
